import { Route } from "wouter"
import HubspotConnectSplash from "../../splashes/HubspotConnectSplash"
import ConstantContactSplash from "../../splashes/ConstantContactSplash"
import StripeConnectSplash from "../../splashes/StripeConnectSplash"

const OAuthRoutes = () => {
	return (
		<>
			<Route path="/connect/hubspot" component={HubspotConnectSplash}/>
			<Route path="/connect/constant-contact" component={ConstantContactSplash}/>
			<Route path="/connect/stripe" component={StripeConnectSplash}/>
		</>
	)
}

export default OAuthRoutes