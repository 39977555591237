import { arrayRemove, arrayUnion, doc, getDoc, setDoc, updateDoc } from 'firebase/firestore'
import { db } from '../config/firebaseConfig'
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'

//---- SUBSCRIBER [GET, CREATE, UPDATE, DELETE] ----//

export const getSubscriber = async(email) => {
	const subscriberObject = doc(db, `/subscribers/${email}`)

	return (await getDoc(subscriberObject)).data()
}

export const createSubscriber = async (userObject) => {
	const { email, displayName: name } = userObject
	const isNewUser	= !(await getDoc(doc(db, `/subscribers/${email}`))).data()
	
	if(isNewUser) {
		return await setDoc(doc(db, `/subscribers/${email}`), {
			accountId: email,
			connected_accounts: [],
			fuzes: [],
			name: name || '',
			returning: false,
			subscription: 'Free Trial (Unlimited)',
		})
	} else {
		return await setDoc(doc(db, `/subscribers/${email}`), {
			returning: true,
		}, { merge: true })
	}
}

//---- SUBSCRIBER [GET, CREATE, UPDATE, DELETE] ----//

//---- FUZE [CREATE, UPDATE, DELETE] ---- //

const generateNewFuzeId = () => {
	return uuidv4().replace(/-/g, '')
}

export const createBlankFuze = async (subscriber, app) => {
	const { accountId } = subscriber
	const fuzeId = `my-new-fuze-${generateNewFuzeId()}`
	const fuzeObject = {
		fuzeId,
		title: 'My New Fuze',
		status: 'pending',
		created_at: moment().unix(),
		updated_at: moment().unix(),
		connected_apps: [app],
		fuzeFlow: {
			trigger: {
				app,
				event: getAppEvent(app)
			},
			actions: [],
		},
	}

	await updateDoc(doc(db, `/subscribers/${accountId}`), {
		'fuzes': arrayUnion(fuzeObject)
	})

	return fuzeId
}

export const updateFuzeTitle = async (subscriber, fuze, updatedTitle) => {
	const { accountId } = subscriber
	const subscriberRef = doc(db, `/subscribers/${accountId}`)
	const fuzes = (await getDoc(subscriberRef)).data().fuzes
	const fuzeIndex = fuzes.findIndex(fuzeObj => fuzeObj.fuzeId === fuze.fuzeId)
	fuzes[fuzeIndex].title = updatedTitle

	fuzes[fuzeIndex].updated_at = moment().unix()

	return await updateDoc(subscriberRef, {
		fuzes: fuzes,
	}, { merge: true })
}

export const handleUpdateFuzeStatus = async (subscriber, fuze, updatedStatus) => {
	const { accountId } = subscriber
	const subscriberRef = doc(db, `/subscribers/${accountId}`)
	const fuzes = (await getDoc(subscriberRef)).data().fuzes
	const fuzeIndex = fuzes.findIndex(fuzeObj => fuzeObj.fuzeId === fuze.fuzeId)
	fuzes[fuzeIndex].status = updatedStatus

	fuzes[fuzeIndex].updated_at = moment().unix()

	return await updateDoc(subscriberRef, {
		fuzes: fuzes,
	}, { merge: true })
}

export const deleteFuze = async (subscriber, fuze) => {
	const { accountId } = subscriber
	const subscriberRef = doc(db, `/subscribers/${accountId}`)

	return await updateDoc(subscriberRef, {
		fuzes: arrayRemove(fuze),
	}, { merge: true })
}

export const createBlankFuzeAction = async (subscriber, fuze, actionIndex) => {
	const { accountId } = subscriber
	const actionId = `fuze-action-${generateNewFuzeId()}`
	const subscriberRef = doc(db, `/subscribers/${accountId}`)
	const fuzes = (await getDoc(subscriberRef)).data().fuzes
	const fuzeIndex = fuzes.findIndex(fuzeObj => fuzeObj.fuzeId === fuze.fuzeId)
	let fuzeActions = [...fuzes[fuzeIndex].fuzeFlow.actions]

	fuzes[fuzeIndex].updated_at = moment().unix()
	
	const fuzeActionObject = {
		id: actionId,
		title: `New Action`,
	}
	
	fuzeActions.splice(actionIndex, 0, fuzeActionObject)
	fuzes[fuzeIndex].fuzeFlow.actions = fuzeActions

	
	await updateDoc(subscriberRef, {
		fuzes: fuzes,
	}, { merge: true })
	
	return actionId
}

export const initializeFuzeAction = async (subscriber, fuze, action, app) => {
	const { accountId } = subscriber
	const subscriberRef = doc(db, `/subscribers/${accountId}`)
	const fuzes = (await getDoc(subscriberRef)).data().fuzes
	const fuzeIndex = fuzes.findIndex(fuzeObj => fuzeObj.fuzeId === fuze.fuzeId)
	let fuzeActions = [...fuzes[fuzeIndex].fuzeFlow.actions]

	fuzes[fuzeIndex].updated_at = moment().unix()

	const appName= app.replace(/\s/g, '_')
	
	fuzeActions[fuzeActions.findIndex((a) => a.id === action.id)].app = appName
	fuzeActions[fuzeActions.findIndex((a) => a.id === action.id)].event = getAppActionEvent(appName)
	
	fuzes[fuzeIndex].fuzeFlow.actions = fuzeActions

	fuzes[fuzeIndex].connected_apps
	.push(appName)

	return await updateDoc(subscriberRef, {
		fuzes: fuzes,
	}, { merge: true })
}

export const deleteAction = async (subscriber, fuze, action) => {
	const { accountId } = subscriber
	const subscriberRef = doc(db, `/subscribers/${accountId}`)
	const fuzes = (await getDoc(subscriberRef)).data().fuzes
	const fuzeIndex = fuzes.findIndex(fuzeObj => fuzeObj.fuzeId === fuze.fuzeId)
	let fuzeActions = [...fuzes[fuzeIndex].fuzeFlow.actions].filter((a) => a.id !== action.id)

	fuzes[fuzeIndex].fuzeFlow.actions = fuzeActions

	fuzes[fuzeIndex].updated_at = moment().unix()

	if(action.app) {
		const appName = action.app
		
		if(appName) {
			fuzes[fuzeIndex].connected_apps = fuzes[fuzeIndex].connected_apps.filter((app) => app !== appName)
		}
	}
	
	return await updateDoc(subscriberRef, {
		fuzes: fuzes,
	}, { merge: true })
}

const updateTriggerFields = async (data) => {

	const {
		subscriber,
		fuze,
		fieldLabel,
		fieldValue,
	} = data

	const { accountId } = subscriber
	const subscriberRef = doc(db, `/subscribers/${accountId}`)
	const fuzes = (await getDoc(subscriberRef)).data().fuzes
	const fuzeIndex = fuzes.findIndex(fuzeObj => fuzeObj.fuzeId === fuze.fuzeId)

	fuzes[fuzeIndex].updated_at = moment().unix()

	if (!fuzes[fuzeIndex].fuzeFlow.trigger.fields) {
		fuzes[fuzeIndex].fuzeFlow.trigger.fields = {}
	}

	fuzes[fuzeIndex].fuzeFlow.trigger.fields[fieldLabel] = fieldValue
	
	return await updateDoc(subscriberRef, {
		fuzes: fuzes,
	}, { merge: true })
}

const updateActionFields = async (data) => {

	const {
		subscriber,
		fuze,
		action,
		fieldLabel,
		fieldValue,
	} = data

	const { accountId } = subscriber
	const subscriberRef = doc(db, `/subscribers/${accountId}`)
	const fuzes = (await getDoc(subscriberRef)).data().fuzes
	const fuzeIndex = fuzes.findIndex(fuzeObj => fuzeObj.fuzeId === fuze.fuzeId)
	const actionIndex = fuzes[fuzeIndex].fuzeFlow.actions.findIndex(actionObj => actionObj.id === action.id)

	fuzes[fuzeIndex].updated_at = moment().unix()
	
	if (!fuzes[fuzeIndex].fuzeFlow.actions[actionIndex].fields) {
		fuzes[fuzeIndex].fuzeFlow.actions[actionIndex].fields = {}
	}

	fuzes[fuzeIndex].fuzeFlow.actions[actionIndex].fields[fieldLabel] = fieldValue
	
	return await updateDoc(subscriberRef, {
		fuzes: fuzes,
	}, { merge: true })
}

export const updateFields = async (data) => {
	const { source } = data

	switch(source) {
		case "Trigger":
			return await updateTriggerFields(data)
		case "Action":
			return await updateActionFields(data)
		default:
			return
	}
}

export const updateCustomFields = async (data) => {

	const {
		subscriber,
		fuze,
		action,
		customFields
	} = data

	const { accountId } = subscriber
	const subscriberRef = doc(db, `/subscribers/${accountId}`)
	const fuzes = (await getDoc(subscriberRef)).data().fuzes
	const fuzeIndex = fuzes.findIndex(fuzeObj => fuzeObj.fuzeId === fuze.fuzeId)
	const actionIndex = fuzes[fuzeIndex].fuzeFlow.actions.findIndex(actionObj => actionObj.id === action.id)

	fuzes[fuzeIndex].updated_at = moment().unix()

	if (!fuzes[fuzeIndex].fuzeFlow.actions[actionIndex].customFields) {
		fuzes[fuzeIndex].fuzeFlow.actions[actionIndex].customFields = []
	}

	fuzes[fuzeIndex].fuzeFlow.actions[actionIndex].customFields = customFields
	
	return await updateDoc(subscriberRef, {
		fuzes: fuzes,
	}, { merge: true })
	
}

export const updateHubspotForm = async (subscriber, fuze, action, form) => {
	const { accountId } = subscriber
	const subscriberRef = doc(db, `/subscribers/${accountId}`)
	const fuzes = (await getDoc(subscriberRef)).data().fuzes
	const fuzeIndex = fuzes.findIndex(fuzeObj => fuzeObj.fuzeId === fuze.fuzeId)
	const actionIndex = fuzes[fuzeIndex].fuzeFlow.actions.findIndex(actionObj => actionObj.id === action.id)

	fuzes[fuzeIndex].updated_at = moment().unix()

	if (!fuzes[fuzeIndex].fuzeFlow.actions[actionIndex].fields) {
		fuzes[fuzeIndex].fuzeFlow.actions[actionIndex].fields = {}
	}

	fuzes[fuzeIndex].fuzeFlow.actions[actionIndex].fields.form = form
	
	return await updateDoc(subscriberRef, {
		fuzes: fuzes,
	}, { merge: true })
}

//---- FUZE [CREATE, UPDATE, DELETE] ---- //

//---- MISC HELPER FUNCTIONS ----//

const getAppEvent = (app) => {
	switch (app) {
		case 'hubspot':
			return 'new_form_submission'
		case 'constant_contact':
			return 'create_contact'
		case 'stripe':
			return 'new_payment'
		default:
			return null
	}
}

const getAppActionEvent = (app) => {
	switch (app) {
		case 'hubspot':
			return 'new_field'
		case 'constant_contact':
			return 'create_contact'
		default:
			return null
	}
}

//---- MISC HELPER FUNCTIONS ----//