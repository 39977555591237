import { GoogleAuthProvider, signInWithPopup } from "firebase/auth"
import LoginWithGoogle from "../../common/LoginWithGoogleButton"
import { auth } from "../../config/firebaseConfig"
import { createSubscriber } from "../../api/firebase"
import FuzeListGraphic from '../../assets/graphic/fuze-list-graphic.svg'


const provider = new GoogleAuthProvider()


const SignUpPage = ({
	setAppUser,
	setLoading,
	setCurrentPage,
}) => {

	const loginWithGoogle = async () => {
		try {
			await signInWithPopup(auth, provider)
			.then((responseObject) => {
				setLoading(true)
				createSubscriber(responseObject._tokenResponse)
				.then(() => {
					setAppUser(responseObject._tokenResponse)
					setLoading(false)
				})
			})
		} catch (error) {
			console.log(error)
		}
	}

	return (
		<div className='pt-[100px] w-full h-full flex flex-col md:flex-row lg:flex-row items-center justify-center bg-white dark:bg-darkThemeBg'>
			<div className="pl-[95px] pr-[50px] hidden w-[100%] md:w-[50%] lg:md:w-[50%] 2xl:ml-[10%] flex-col justify-center items-start md:flex lg:flex">
				<span className="text-fuzerPurp text-3xl 2xl:text-4xl font-medium">Automate your work with <span className="font-extrabold">Fuzer</span>.</span>
				<div className="mt-[35px] w-full flex justify-start items-center">
					<div className="list-bullet"/>
					<span className="bullet-item">Easy no-code setup</span>
				</div>
				<div className="mt-[10px] w-full flex justify-start items-center">
					<div className="list-bullet"/>
					<span className="bullet-item">Multiple apps supported</span>
				</div>
				<img className="mt-[50px] h-[300px] md:h-[300px] lg:h-[300px] 2xl:h-[450px] pointer-events-none" src={FuzeListGraphic} alt="" />
			</div>
			<div className="md:pr-[150px] lg:pr-[150px] w-[100%] md:w-[50%] lg:md:w-[50%] h-fit md:h-[500px] lg:h-[500px] flex flex-col items-center justify-start">
				{/* <span className="mb-[35px] text-[28px] font-semibold">Sign up to Fuzer</span> */}
				<LoginWithGoogle onClick={loginWithGoogle}  />
				<div className="mt-[25px]">
					<span className="dark:text-white">Already have an account?</span>
					<button className="ml-[5px] link-text" onClick={() => setCurrentPage('login')}>Login</button>
				</div>
				<span className="mt-[25px] max-w-[80%] text-center dark:text-white">
					By signing up, you agree to Fuzer's <span className="link-text">terms of service</span> and <span className="link-text">privacy policy</span>.
				</span>
			</div>
			<div className="flex mt-[80px] w-[80%] md:w-[50%] lg:md:w-[50%] flex-col justify-center items-center md:hidden lg:hidden">
				<span className="text-fuzerPurp text-3xl">Automate your work with <span className="font-extrabold">Fuzer</span>.</span>
				<div className="mt-[35px] w-full flex justify-start items-center">
					<div className="list-bullet"/>
					<span className="bullet-item">Easy no-code setup</span>
				</div>
				<div className="mt-[10px] w-full flex justify-start items-center">
					<div className="list-bullet"/>
					<span className="bullet-item">Multiple apps supported</span>
				</div>
				<img className="mt-[50px] mb-[50px] h-[300px] md:h-[450px] lg:h-[300px] pointer-events-none" src={FuzeListGraphic} alt="" />
			</div>
		</div>
	)
}

export default SignUpPage