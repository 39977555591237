import toast from "react-hot-toast"

const Notification = (message, type, props) => type ? toast?.[type](
	message,
	{
	...props,
		style: {
			borderRadius: '10px',
			background: '#8C15FF', 
			border: '2px solid #5600AA',
			padding: '12px',
			color: '#FFFAEE',
			fontWeight: 'bold',
		},
		iconTheme: {
			primary: '#FFFAEE',
			secondary: '#8C15FF',
		},
	}) : toast(
		message,
		{
			...props,
			style: {
				borderRadius: '10px',
				background: '#8C15FF', 
				border: '2px solid #5600AA',
				padding: '12px',
				color: '#FFFAEE',
				fontWeight: 'bold',
			},
			iconTheme: {
				primary: '#FFFAEE',
				secondary: '#8C15FF',
			},
		})

export default Notification