import GoogleIcon from '../../assets/icons/google-icon.svg'

const LoginWithGoogle = (props) => {
	return (
		<div className='flex justify-between items-center w-fit pl-4 pr-4 pt-2 pb-2 bg-white dark:bg-darkThemeBg shadow-xl border-2 border-fuzerPurp rounded-full cursor-pointer hover:opacity-[85%]' {...props}>
			<img className='h-[50px]' src={GoogleIcon} alt='google icon'/>
			<span className='ml-4 text-fuzerPurp dark:text-white text-[20px] md:text-[25px] lg:text-[30px] select-none'>Continue with Google</span>
		</div>
	)
}

export default LoginWithGoogle