import axios from "axios"
import { useEffect, useState } from "react"
import WindowSplashLoader from "../common/WindowSplashLoader"

const HubspotConnectSplash = () => {

  const [isInsideWindow] = useState(!!window.opener)
  const [accountId] = useState(new URLSearchParams(window.location.search).get('accountId'))
  const [accessToken] = useState(new URLSearchParams(window.location.search).get('accessToken'))
  
  
  useEffect(() => {
    const resolveAsync = async () => {
        if(!accessToken) {
          const getHubspotAuthAccess = {
            url: process.env.REACT_APP_HUBSPOT_ACCESS_URL,
            method: 'GET',
          }
          
          if(accountId) {
            const hubspotAccess = (await axios(getHubspotAuthAccess)).data
            const state = encodeURIComponent(JSON.stringify({
              accountId,
            }))
            window.location = `${hubspotAccess.installUrl}&state=${state}`
          }
        } else {
          const dataToSend = {
            access_token: accessToken,
          }
          
          window.opener.postMessage(dataToSend, window.location.origin)
        }
    }

    if (isInsideWindow) {
      resolveAsync()
    }
  }, [accountId, accessToken, isInsideWindow])

  return (
    <div className="flex items-center justify-center app h-screen w-full bg-white">
      {
        isInsideWindow
        ?
        <WindowSplashLoader app={"hubspot"} />
        :
        <div className="h-screen w-full grid place-items-center">
          <span>Not in a window</span>
        </div>
      }
    </div>
  )
}

export default HubspotConnectSplash