import React, { useEffect, useState } from 'react'
import { useLocation } from 'wouter'
import { ReactComponent as BackArrow } from '../../assets/icons/back_arrow.svg'
import FuzerIcon from '../../assets/icons/fuzer-icon-white.svg'
import CheckIcon from "../../assets/icons/check-icon.svg"
import NoIcon from "../../assets/icons/no-icon.svg"
import { handleUpdateFuzeStatus, updateFuzeTitle } from "../../api/firebase"
import SmallLoader from "../../components/SmallLoader"
import Notification from "../../common/Notification"

function FuzePageHeader({
	fuze,
	subscriber,
	reloadSubscriber,
	setShowConfirmDiscardFuzeEditsModal,
}) {

	// eslint-disable-next-line
	const [location, navigate] = useLocation()

	const [fuzeTitle, setFuzeTitle] = useState('')
	const [updatingFuzeTitle, setUpdatingFuzeTitle] = useState(false)
	const [updatingFuzeStatus, setUpdatingFuzeStatus] = useState(false)

	const [isModalEdited, setIsModalEdited] = useState(false)

	useEffect(() => {
		if(fuze) {
			setFuzeTitle(fuze.title)
		}
	}, [fuze])

	const handleGoBack = () => {
		if(isModalEdited) {
			setShowConfirmDiscardFuzeEditsModal(true)
		} else {
			navigate('/')
		}
	}

	const handleUpdateFuzeTitle = (e) => {
		e.preventDefault()
		const title = fuzeTitle || 'Untitled Fuze'
		setUpdatingFuzeTitle(true)
		updateFuzeTitle(subscriber, fuze, title)
		.then(async() => {
			await reloadSubscriber()
			setUpdatingFuzeTitle(false)
		})
	}

	useEffect(() => {
		if(fuze) {
			if(fuze.title !== fuzeTitle) {
				setIsModalEdited(true)
			} else {
				setIsModalEdited(false)
			}
		}
	}, [subscriber, fuze, fuzeTitle])

	const handleOnClickFuzeActionButton = async () => {
		setUpdatingFuzeStatus(true)

		switch(fuze.status) {
			case 'pending':
				return handleUpdateFuzeStatus(subscriber, fuze, 'active')
				.then(() => {
					reloadSubscriber()
					.then(() => {
						setUpdatingFuzeStatus(false)
						Notification('Your fuze is now active!', '', { icon: '🎉' })
					})
				})
			case 'paused':
				return handleUpdateFuzeStatus(subscriber, fuze, 'active')
				.then(() => {
					reloadSubscriber()
					.then(() => {
						setUpdatingFuzeStatus(false)
						Notification('Your fuze is now active!', '', { icon: '🎉' })
					})
				})
			case 'active':
				return handleUpdateFuzeStatus(subscriber, fuze, 'paused')
				.then(() => {
					reloadSubscriber()
					.then(() => {
						setUpdatingFuzeStatus(false)
						Notification('Your fuze is now paused!', '', { icon: '⌛️' })
					})
				})
			default:
				return
		}
	}

	const isFuzePublishable = () => {
		return fuze?.fuzeFlow?.trigger && fuze?.fuzeFlow?.actions?.[0]?.app
	}

	return (
		<div className="flex flex-col md:flex-row lg:flex-row justify-between items-center p-4 w-full bg-fuzerPurpDark">
			<div className="flex-[0.4] flex flex-col md:flex-row lg:flex-row justify-start items-center">
				<span className="rounded-lg w-fit h-fit shadow-outlined shadow-white cursor-pointer hover:bg-white transition-colors" onClick={handleGoBack}>
					<BackArrow className="p-4 box-content w-[25px] h-[25px] fill-white hover:fill-fuzerPurpDark" />
				</span>
				<form className="mt-4 mb-4 md:m-0 lg:m-0 flex flex-col md:flex-row lg:flex-row items-center" onSubmit={handleUpdateFuzeTitle}>
					<input
						type="text"
						style={{
							cursor: fuze.fuzeFlow.trigger === null ? 'not-allowed' : 'inherit'
						}}
						className="rounded pl-2 pr-2 w-full ml-[15px] text-white text-lg font-bold bg-transparent max-w-[850px] mr-2 outline-none border-[1px] border-fuzerPurp focus:bg-fuzerPurp disabled:opacity-80 disabled:cursor-progress"
						value={fuzeTitle}
						onChange={(e) => setFuzeTitle(e.target.value)}
						disabled={updatingFuzeTitle || fuze.fuzeFlow.trigger === null}
					/>
					{
						!updatingFuzeTitle
						&&
						fuze.title !== fuzeTitle
						&&
						<div className="flex gap-2">
							<span className="h-[20px] w-[20px] cursor-pointer hover:opacity-80">
									<img className="" src={CheckIcon} alt="check icon" onClick={handleUpdateFuzeTitle} />
							</span>
							<span className="h-[20px] w-[20px] cursor-pointer hover:opacity-80">
								<img className="" src={NoIcon} alt="no icon" onClick={handleUpdateFuzeTitle} />
							</span>
						</div>
					}
					{
						updatingFuzeTitle
						&&
						<SmallLoader />
					}
				</form>
			</div>
			<div className="flex-[0.2] flex justify-center">
				<img className="h-[50px] select-none pointer-events-none animate-scaleUp" src={FuzerIcon} alt="fuzer icon" />
			</div>
			<div className="mt-4 md:m-0 lg:m-0 flex-[0.4] flex justify-end">
				{
					updatingFuzeStatus
					&&
					<span className="fuze-action-button hover:bg-fuzerPurp hover:text-black text-white cursor-not-allowed">...</span>
				}
				{
					!updatingFuzeStatus && fuze.status === 'pending' && isFuzePublishable() && <button className="fuze-action-button" onClick={handleOnClickFuzeActionButton}>Publish Fuze</button>
				}
				{
					!updatingFuzeStatus && fuze.status === 'paused' && <button className="fuze-action-button" onClick={handleOnClickFuzeActionButton}>Resume Fuze</button>
				}
				{
					!updatingFuzeStatus && fuze.status === 'active' && <button className="fuze-action-button" onClick={handleOnClickFuzeActionButton}>Pause Fuze</button>
				}
			</div>
		</div>
	)
}

export default FuzePageHeader