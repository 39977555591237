import { useEffect, useState } from "react"
import { updateFields } from "../../api/firebase"
import { getStripePaymentLinks } from "../../api/stripe"

const StripeFields = ({
	source,
	subscriber,
	fuze,
	action,
	reloadSubscriber,
}) => {

	const [productPaymentLinks, setProductPaymentLinks] = useState([])
	const [selectedProductPaymentLink, setSelectedProductPaymentLink] = useState(null)

	const [loading, setLoading] = useState('false')

	useEffect(() => {
		if(source === 'Trigger') {
			setSelectedProductPaymentLink(fuze?.fuzeFlow?.trigger?.fields?.stripe_plink_id || null)
		}
		// eslint-disable-next-line
	}, [fuze])

	const handeUpdateProductPaymentLink = async (p_link) => {
		setLoading('p_links-loading')
		
		if(p_link !== selectedProductPaymentLink) {
			setSelectedProductPaymentLink(null)
			updateFields({
				source,
				subscriber,
				fuze,
				action,
				fieldLabel: 'stripe_plink_id',
				fieldValue: p_link,
			})
			.then(() => {
				reloadSubscriber()
				setLoading(false)
			})
		}
	}

	const loadStripePaymentLinks = (accountId) => {
		setLoading('p_links-loading')
		getStripePaymentLinks(accountId)
			.then((p_links) => {
				setProductPaymentLinks(p_links)
				setLoading(false)
			})
	}

	useEffect(() => {
		loadStripePaymentLinks(subscriber.accountId)
		// eslint-disable-next-line
	}, [])

	return (
			<>
				<div className='mt-[25px] pl-[15px] pr-[15px] flex flex-col w-[95%] h-[80px]'>
					<span className='text-black dark:text-white text-xl font-medium select-none'><b>* Payment Link</b> (required)</span>
					
					<select className={`mt-2 text-black dark:text-white bg-transparent appearance-none border-2 p-2 outline-none text-2xl font-medium cursor-pointer select-none disabled:opacity-50 ${loading === 'p_links-loading' && 'skeleton-loading'}`} value={selectedProductPaymentLink} onChange={(e) => handeUpdateProductPaymentLink(e.target.value)} disabled={productPaymentLinks.length===0}>
						{selectedProductPaymentLink === null && <option value="default" defaultValue>Select</option>}

						{
							productPaymentLinks.map((link) => (
								<option key={link} value={link}>{link}</option>
							))
						}
					</select>
				</div>
			</>
	)
}

export default StripeFields