

const Modal = (props) => {
	return (
		<div className={`absolute top-0 grid place-items-center h-screen w-full backdrop-blur-[5px]`}>
			<div className={`rounded-3xl p-[8px] overflow-hidden bg-fuzerPurp animate-slideFromTop ${props.customClass}`}>
				<div className="rounded-2xl bg-white backdrop-blur-2xl w-full h-full">
					<div className="p-4 grid place-items-center bg-fuzerPurp text-white text-[20px] font-bold">
						{props.title}
					</div>
					{props.children}
				</div>
			</div>
		</div>
	)
}

export default Modal