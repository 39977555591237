import { onAuthStateChanged, signOut } from "firebase/auth";
import { useEffect, useState } from "react";
import { Route, Switch, useLocation } from "wouter";
import { getSubscriber } from "./api/firebase";
import AppLoader from "./common/AppLoader";
import Header from "./components/Header";
import { auth, db } from "./config/firebaseConfig";
import DashboardPage from "./pages/DashboardPage";
import FuzePage from "./pages/FuzePage";
import LoginPage from "./pages/LoginPage";
import Page404 from "./pages/Page404";
import { collection, getDocs } from "firebase/firestore";
import SignUpPage from "./pages/SignUpPage";
import { Toaster } from "react-hot-toast";
import OAuthRoutes from "./components/OAuthRoutes";

function App() {
  const [loading, setLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState('')
  const [appUser, setAppUser] = useState({})
  const [subscriber, setSubscriber] = useState (null)
  // eslint-disable-next-line
  const [location, navigate] = useLocation()

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if(user) {
        setAppUser(user)
        setLoading(false)
      } else {
        setAppUser(null)
        setLoading(false)
      }
    })
  }, [])

  useEffect(() => {
		if(appUser !== null && appUser !== {}) {
			reloadSubscriber()
		}
    // eslint-disable-next-line
	}, [appUser])

  useEffect(() => {
    if(appUser !== {}) {
      if(appUser !== null) {
      setCurrentPage('dashboard')
    } else {
      setCurrentPage('login')
    }
    }
  }, [appUser])

  useEffect(() => {
    const resolveAsync = async () => {
      const subscribersObject = await getDocs(collection(db, 'subscribers'))
      const activeFuzes = []
      subscribersObject.forEach((sub) => {
        activeFuzes.push(
          ...sub.data().fuzes
          .filter(f => f.status !== 'pending')
          .filter(f => f.status !== 'paused')
        )
      })
    }

    return resolveAsync
  }, [])

  const reloadSubscriber = async () => {
    await getSubscriber(appUser.email)
			.then((subscriber) => {
				setSubscriber(subscriber)
			})
  }

  const getCurrentPage = () => {
    switch(currentPage) {
      case 'login':
        return <LoginPage setAppUser={setAppUser} setLoading={setLoading} setCurrentPage={setCurrentPage}/>
      case 'signup':
        return <SignUpPage setAppUser={setAppUser} setLoading={setLoading} setCurrentPage={setCurrentPage}/>
      case 'dashboard':
        return <DashboardPage subscriber={subscriber} reloadSubscriber={reloadSubscriber} />
      default:
        return <Page404 />
    }
  }

  const handleLogout = () => {
    setAppUser(null)
    setSubscriber(null)
		signOut(auth)
	}

  return (
    <div className="min-h-screen flex flex-col w-full bg-lightThemeBg dark:bg-darkThemeBg">
       <Toaster
        position={location === '/' ? 'bottom-center' : 'top-center'}
        reverseOrder={false}
        containerStyle={{ marginBottom: 50, marginTop: 0}}
        toastOptions={{
          duration: 3000,
        }}
      />
      <Route path='/'>
        <Header currentPage={currentPage} setCurrentPage={setCurrentPage} subscriber={subscriber} handleLogout={handleLogout} loading={loading}/>
      </Route>
      <div className='flex h-full items-center w-full bg-transparent'>
        {
          !loading
          ?
            <Switch>
              <Route path='/' component={getCurrentPage} />
              <Route path='/fuze/:fuzeId'>
                {params => <FuzePage fuzeId={params.fuzeId} subscriber={subscriber} reloadSubscriber={reloadSubscriber} />}
              </Route>

              {/* OAuth Window Splash */}
              <OAuthRoutes />
            </Switch>
          :
          <AppLoader />
        }
      </div>
    </div>
  );
}

export default App;
