import { GoogleAuthProvider, signInWithPopup } from "firebase/auth"
import LoginWithGoogle from "../../common/LoginWithGoogleButton"
import { auth } from "../../config/firebaseConfig"
import { createSubscriber } from "../../api/firebase"
import FuzeListGraphic from '../../assets/graphic/fuze-list-graphic.svg'

const provider = new GoogleAuthProvider()


const LoginPage = ({
	setAppUser,
	setLoading,
	setCurrentPage,
}) => {

	const loginWithGoogle = async () => {
		try {
			await signInWithPopup(auth, provider)
			.then((responseObject) => {
				setLoading(true)
				createSubscriber(responseObject._tokenResponse)
				.then(() => {
					setAppUser(responseObject._tokenResponse)
					setLoading(false)
				})
			})
		} catch (error) {
			console.log(error)
		}
	}

	return (
		<div className='pt-[100px] w-full h-full flex flex-col md:flex-row lg:flex-row items-start justify-center bg-lightThemeBg dark:bg-darkThemeBg'>
			<div className="pl-[95px] pr-[50px] hidden w-[100%] md:w-[50%] lg:md:w-[50%] 2xl:ml-[10%] flex-col justify-center items-start md:flex lg:flex">
				<span className="text-fuzerPurp text-3xl 2xl:text-4xl font-medium">Connect your apps, <span className="font-extrabold">seamlessly</span>.</span>
				<img className="mt-[50px] h-[300px] md:h-[300px] lg:h-[300px] 2xl:h-[450px] pointer-events-none" src={FuzeListGraphic} alt="" />
			</div>
			<div className="md:pr-[150px] lg:pr-[150px] w-[100%] md:w-[50%] lg:md:w-[50%] h-fit md:h-[500px] lg:h-[500px] flex flex-col items-center justify-start">
				<span className="mb-[35px] text-[28px] font-semibold text-black dark:text-white">Login to your account</span>
				<LoginWithGoogle onClick={loginWithGoogle}  />
				<div className="mt-[25px] flex text-black dark:text-white">
					<span>Don't have a Fuzer account yet?</span>
					<button className="ml-[5px] text-fuzerPurpDark dark:text-fuzerPurp underline" onClick={() => setCurrentPage('signup')}>Sign Up</button>
				</div>

			</div>
			<div className="flex ml-auto mr-auto mt-[80px] mb-12 w-[80%] md:w-[50%] lg:md:w-[50%] flex-col justify-center items-center md:hidden lg:hidden">
				<span className="text-fuzerPurp text-3xl font-medium">Connect your apps, <span className="font-extrabold">seamlessly</span>.</span>
				<img className="mt-[50px] h-[300px] md:h-[450px] lg:h-[300px] pointer-events-none" src={FuzeListGraphic} alt="" />
			</div>
		</div>
	)
}

export default LoginPage