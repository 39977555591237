import ConstantContactFields from "../fields/ConstantContactFields"
import HubspotFields from "../fields/HubspotFields"

const AdditionalFields = ({
	subscriber,
	fuze,
	action,
	reloadSubscriber,
}) => {
	switch(action.app) {
		case 'hubspot':
			return <HubspotFields
				source="Action"
				subscriber={subscriber}
				fuze={fuze}
				action={action}
				reloadSubscriber={reloadSubscriber}
			/>
		case 'constant_contact':
			return <ConstantContactFields
				source="Action"
				subscriber={subscriber}
				fuze={fuze}
				action={action}
				reloadSubscriber={reloadSubscriber}
			/>
		default:
			return
	}
}

export default AdditionalFields