import FuzerLogo from '../../assets/fuzer-logo-white.svg'
import Avatar from '../../common/Avatar'
import LoginButton from '../../common/LoginButton'
import LogOutButton from '../../common/LogOutButton'
import SignUpButton from '../../common/SignUpButton'

const Header = ({
	subscriber,
	currentPage,
	setCurrentPage,
	handleLogout,
	loading,
}) => {

	return (
		<div className="top-0 w-full pt-[20px] pb-[20px] flex justify-center items-center bg-fuzerPurp z-[999]">
			<div className="flex flex-col md:flex-row justify-between items-center w-[60%]">
				<img className='h-[80px] cursor-pointer hover:opacity-[85%] animate-slideFromTop' src={FuzerLogo} alt="fuzer logo" />
				<span className='flex gap-5 mt-10 md:m-0'>
					{subscriber
						?
						<>
							<LogOutButton onClick={handleLogout}/>
						</>
						:
						currentPage !== 'login'
						&&
						!loading
						?
						<>
							<LoginButton onClick={() => setCurrentPage('login')}/>
						</>
						:
						!loading
						&&
						<>
							<SignUpButton onClick={() => setCurrentPage('signup')}/>
						</>
					}
					{ subscriber !== null && subscriber !== undefined && !loading && <Avatar name={subscriber?.name} img_url={subscriber?.img_url} /> }
				</span>
			</div>
		</div>
	)
}

export default Header