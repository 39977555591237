const Avatar = ({
	name,
	img_url,
}) => {
	return(
		<div className={`grid place-items-center w-[50px] h-[50px] rounded-full bg-white cursor-pointer`}>
			{ (name && !img_url) && <span className={`text-fuzerPurp text-3xl font-medium select-none`}>{name[0]}</span> }
			{ (img_url) && <img src={img_url} alt="avatar" /> }
		</div>
	)
}

export default Avatar