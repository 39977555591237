import { useEffect, useState } from "react"
import { updateFields } from "../../api/firebase"
import { getCCLists } from "../../api/constant_contact"

const ConstantContactFields = ({
	source,
	subscriber,
	fuze,
	action,
	reloadSubscriber,
}) => {

	const [lists, setLists] = useState([])
	const [selectedList, setSelectedList] = useState('default')

	const [loading, setLoading] = useState('false')

	const loadCCLists = (accountId) => {
		setLoading('lists-loading')
		getCCLists(accountId)
			.then((lists) => {
				setLists(lists)
				setLoading(false)
			})
	}

	useEffect(() => {
		loadCCLists(subscriber.accountId)
		// eslint-disable-next-line
	}, [])

	useEffect(() => {
		if(action?.fields?.list) {
			setSelectedList(action.fields.list)
		}
	}, [action])
	
	const handleUpdateLists = (list) => {
		setLoading('lists-loading')
		
		if(list !== selectedList) {
			setSelectedList(null)
			updateFields({
				source,
				subscriber,
				fuze,
				action,
				fieldLabel: 'list',
				fieldValue: list,
			})
			.then(() => {
				reloadSubscriber()
				setLoading(false)
			})
		}
	}

	return (
			<>
				<div className='mt-[25px] pl-[15px] pr-[15px] flex flex-col w-[95%] h-[80px]'>
					<span className='text-black dark:text-white text-xl font-medium select-none'><b>* LIST </b> (required)</span>
					
					<select className={`mt-2 text-black dark:text-white bg-transparent appearance-none border-2 p-2 outline-none text-2xl font-medium cursor-pointer select-none disabled:opacity-50 ${loading === 'lists-loading' && 'skeleton-loading'}`} value={selectedList} onChange={(e) => handleUpdateLists(e.target.value)} disabled={lists.length===0}>
						{selectedList === 'default' && <option value="default" defaultValue>Select value</option>}

						{selectedList !== null
						&&
							lists.map((list) => (
								<option key={list.list_id} value={list.list_id}>{list.name}</option>
							))
						}
					</select>
				</div>
			</>
	)
}

export default ConstantContactFields