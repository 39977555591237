import React, { useEffect, useState } from 'react'
import app_list from '../../utils/action_apps_list.json'
import FlowIcon from '../../assets/icons/flow-icon.svg'
import Loader from '../../common/Loader'
import MoreIcon from '../../assets/icons/more-icon.svg'
import { getActionEventSelectOptions, getAppActionEventDescription } from '../../utils/helper'
import { deleteAction, initializeFuzeAction } from '../../api/firebase'
import AdditionalFields from '../../actions/AdditionalFields'

const FuzeActionComponent = ({
	subscriber,
	fuze,
	action,
	reloadSubscriber,
}) => {
	const [fuzeAction, setFuzeAction] = useState(null)
	const [fuzeActionEvent, setFuzeActionEvent] = useState(null)
	const [loading, setLoading] = useState(false)

	const [hubspotConnectPopup, setHubspotConnectPopup] = useState(null)
	const [constantConnectPopup, setConstantConnectPopup] = useState(null)

	const [deletingAction, setDeletingAction] = useState(false)
	const [actionOptions, setActionOptions] = useState(false)
				
	const handleClosePopup = () => {
		switch(fuzeAction.app) {
			case 'hubspot':
				if(hubspotConnectPopup !== null) {
					hubspotConnectPopup.close()
				}
				break
			case 'constant_contact':
				if(constantConnectPopup !== null) {
					constantConnectPopup.close()
				}
				break
			default:
				return
		}
	}

	const handlePopupMessage = async (event) => {
		// Check the origin of the message to ensure it's from a trusted source
		if (event.origin !== window.location.origin) {
			return
		}

		const data = event.data

		if(data.access_token) {
			setLoading('action-app-account-loading')
			reloadSubscriber()
			.then(() => {
				handleClosePopup()
				setLoading(null)
			})
		}
	}

	const handleTriggerAccount = async (app) => {
		switch(app) {
			case 'hubspot':
				return setHubspotConnectPopup(window.open(`${window.location.origin}/connect/hubspot?accountId=${subscriber.accountId}`, "Connecting Hubspot account", "width=965,height=655"))
			case 'constant_contact':
				return setConstantConnectPopup(window.open(`${window.location.origin}/connect/constant-contact?accountId=${subscriber.accountId}`, "Connecting Constant Contact account", "width=965,height=655"))
			default:
				return null
		}
	}

	const handleShowActionOptions = () => {
		setActionOptions(!actionOptions)
	}

	const handleDeleteAction = async () => {
		setDeletingAction(true)
		deleteAction(subscriber, fuze, action)
		.then(() => {
			reloadSubscriber()
			.then(() => {
				setDeletingAction(false)
			})
		})
	}
	
	const handleChooseAction = async (app) => {
		setLoading('fuze-loading')
		
		initializeFuzeAction(subscriber, fuze, action, app)
		.then(() => {
			reloadSubscriber()
			.then(() => {
				setLoading(false)
			})
		})
	}

	useEffect(() => {
		if(fuzeAction !== null) {
			// Add the event listener when the component mounts
			window.addEventListener('message', handlePopupMessage)
	
			// Remove the event listener when the component unmounts
			return () => {
				window.removeEventListener('message', handlePopupMessage)
			}
		}
		// eslint-disable-next-line
	}, [fuzeAction, hubspotConnectPopup, constantConnectPopup])

	useEffect(() => {
		if(fuze) {
			if(fuze?.fuzeFlow?.trigger) {
				setFuzeAction(action)

				if(action?.event) {
					setFuzeActionEvent(action.event)
				}
			}
		}
	}, [subscriber, fuze, action])

	const ComingSoonWrapper = () => (
		<div className='mt-[15px] flex items-center flex-wrap gap-2 select-none pointer-events-none'>
			<p className='pt-4 text-slate-300 dark:text-slate-600'>🧪 |</p>
			<p className='w-fit rounded-lg mt-4 p-2 bg-fuzerPurp bg-opacity-25 text-black dark:text-white text-md font-semibold'>⚡️ multi-account connections coming soon</p>
			<p className='w-fit rounded-lg mt-4 p-2 bg-fuzerPurp bg-opacity-25 text-black dark:text-white text-md font-semibold'>⚡️ custom fields coming soon</p>
		</div>
	)

	return (
		<div className={`overflow-hidden rounded-2xl max-w-[895px] w-[90%] min-h-[300px] ml-auto mr-auto border-[1px] border-fuzerPurpDark shadow-xl animate-scaleUp ${deletingAction ? 'pointer-events-none opacity-50' : ''}`}>
			<div className="w-full h-full">
				<div className={`pt-6 md:pt-0 lg:pt-0 flex flex-col md:flex-row lg:flex-row items-center pl-[15px] w-full h-full md:h-[100px] lg:h-[100px] ${!action?.app ? 'bg-fuzerPurpDark' : 'bg-fuzerPurp bg-opacity-10'}`}>
					<div className="flex w-full">
						<img className='h-[55px] w-[55px] mr-[15px]' src={!action?.app ? FlowIcon : require(`../../assets/icons/apps/${app_list.find((app) => app.id.toLowerCase() === action.app.toLowerCase()).icon}`)} alt="flow icon" />
						<div className="w-[70%] flex flex-col">
								<span className={`w-fit text-lg md:text-3xl lg:text-3xl font-bold ${fuzeActionEvent !== null ? 'text-fuzerPurpDark dark:text-white' : 'text-white dark:text-white'}`}>{!action?.app ? "Action" : `${app_list.find((app) => app.id.toLowerCase() === action.app.toLowerCase()).name} --> ${action.title ? action.title : 'Action'}`}</span>
								<span className={`mt-[2px] text-sm md:text-base lg:text-base font-medium ${fuzeActionEvent !== null ? 'text-fuzerPurpDark dark:text-white' : 'text-white dark:text-white'}`}>An action is an event a Fuze performs after it starts</span>
						</div>
					</div>
					<div className="relative ml-auto mr-[50px] pb-4 md:p-0 lg:p-0">
						<span className={`mt-2 grid place-items-center rounded-full w-[35px] h-[35px] ml-[35px] ${fuzeActionEvent === null ? 'bg-white hover:bg-white hover:bg-opacity-80' : ''} ${actionOptions && fuzeActionEvent !== null ? 'bg-fuzerPurp bg-opacity-10' : ''} transition-colors hover:bg-fuzerPurp hover:bg-opacity-10 cursor-pointer select-none`} onClick={handleShowActionOptions}>
							<img className="h-[6px]" src={MoreIcon} alt="more icon" />
						</span>
						{
							actionOptions
							&&
							<div className="absolute top-[50px] right-0 mb-[10px] rounded-2xl p-2 flex flex-col gap-2 justify-center w-[200px] border-opacity-40 z-[999]">
								<button className="fuzer-button text-[16px] bg-red-500 hover:bg-red-600 text-white animate-slideFromRight animation-delay" onClick={handleDeleteAction}>Delete Action</button>
							</div>
						}
					</div>
				</div>
				{
					fuzeActionEvent === null
					?
					<div className='h-full md:h-[200px] lg:h-[200px] pt-6 pb-6 md:p-0 w-full flex flex-col justify-center items-center flex-wrap'>
						{
							loading !== 'fuze-loading'
							?
							<>
								<span className='mt-4 md:mt-0 lg:mt-0 mb-[25px] text-xl font-semibold text-fuzerPurpDark dark:text-white'>Choose an app for the action</span>
								<div className='flex flex-col md:flex-row lg:flex-row flex-wrap'>
									{
										app_list.map((app) => (
											<div key={app.name} className='mt-2 rounded-xl pt-2 pb-2 pl-4 pr-4 ml-2 mr-2 flex items-center border-2 border-fuzerPurpDark cursor-pointer transition-opacity hover:opacity-70' onClick={() => handleChooseAction(app.name.toLowerCase())}>
												<img className='w-[50px] mr-4 pointer-events-none' src={require(`../../assets/icons/apps/${app.icon}`)} alt="app icon" />
												<span className='text-fuzerPurpDark dark:text-white text-2xl font-semibold select-none'>{app.name}</span>
											</div>
										))
									}
								</div>
							</>
							:
							<Loader size={80} />
						}
					</div>
					:
					<div className='pt-[15px] pb-[15px] flex flex-col items-center w-full'>
						<div className='pl-[15px] pr-[15px] flex items-center rounded-lg w-[95%] h-[80px] border-[1px] border-gray-300'>
							<img className='w-[30px] mr-4 pointer-events-none' src={require(`../../assets/icons/apps/${app_list.find((app) => app.id.toLowerCase() === fuzeAction.app.toLowerCase()).icon}`)} alt="flow icon" />
							<span className='text-black dark:text-white text-2xl font-semibold select-none'>{app_list.find((app) => app.id.toLowerCase() === fuzeAction.app.toLowerCase()).name}</span>
							{/* <button className='change-trigger-button ml-auto'>Change</button> */}
						</div>
						<div className='mt-[15px] pl-[15px] pr-[15px] flex flex-col w-[95%] h-fit'>
							<span className='text-black dark:text-white text-md font-medium select-none'><b>* {app_list.find((app) => app.id.toLowerCase() === fuzeAction.app.toLowerCase()).name} account</b> (required)</span>
							<div className='flex'>
								{
									loading !== 'action-app-account-loading' ?
									subscriber?.connected_accounts[fuzeAction.app]
									?
									<div className='mt-2 pl-[15px] pr-[15px] p-4 flex flex-col md:flex-row lg:flex-row md:items-center lg:items-center w-full h-full md:h-[80px] lg:h-[80px] border-[1px] border-gray-300'>
										<img className='w-[20px] mr-4 pointer-events-none' src={require(`../../assets/icons/apps/${app_list.find((app) => app.id.toLowerCase() === fuzeAction.app.toLowerCase()).icon}`)} alt="flow icon" />
										<span className='text-black dark:text-white text-lg font-semibold select-none'>{`${subscriber.connected_accounts[fuzeAction.app]?.user?.email} ${subscriber.connected_accounts[fuzeAction.app]?.user?.portalId ? `(${subscriber.connected_accounts[fuzeAction.app]?.user?.portalId})` : ''}` || 'Connect a new account'}</span>

										<button className='change-trigger-account-button h-[45px] ml-auto' onClick={() => handleTriggerAccount(fuzeAction.app)}>{subscriber.connected_accounts[fuzeAction.app] ? 'Update' : 'Connect'}</button>
									</div>
									:
									<div className='mt-2 pl-[15px] pr-[15px] p-4 flex flex-col md:flex-row lg:flex-row md:items-center lg:items-center w-full h-full md:h-[80px] lg:h-[80px] border-[1px] border-gray-300'>
										<img className='w-[20px] mr-4 pointer-events-none' src={require(`../../assets/icons/apps/${app_list.find((app) => app.id.toLowerCase() === fuzeAction.app.toLowerCase()).icon}`)} alt="flow icon" />
										<span className='text-black dark:text-white text-2xl font-semibold select-none'>Connect a new account</span>
										<button className='change-trigger-account-button h-[45px] ml-auto' onClick={() => handleTriggerAccount(fuzeAction.app)}>Connect</button>
									</div>
									:
									<div className='mt-2 skeleton-loading w-full h-[80px] rounded-lg' />
								}
								</div>
								<div className='mt-[15px] pl-[15px] pr-[15px] flex flex-col w-[95%] h-[80px]'>
									<span className='text-black dark:text-white text-xl font-medium select-none'><b>* Event</b> (required)</span>
									
									<select className='mt-2 text-black dark:text-white bg-transparent appearance-none border-2 p-2 outline-none text-2xl font-medium cursor-pointer select-none' value={fuzeActionEvent} onChange={(e) => setFuzeActionEvent(e.target.value)}>
										{getActionEventSelectOptions(fuzeAction.app)}
									</select>
								</div>
								<p className='rounded bg-fuzerPurpDark bg-opacity-20 w-fit p-2 pl-4 pr-4 mt-[25px] ml-4 mb-2 text-base text-black dark:text-white font-semibold'>
									{getAppActionEventDescription(fuzeAction.app, fuzeAction.event)}
								</p>
								<AdditionalFields
									subscriber={subscriber}
									fuze={fuze}
									action={action}
									reloadSubscriber={reloadSubscriber}
								/>
								{/* COMING SOON CONTAINER */}
								<ComingSoonWrapper />
						</div>
					</div>
				}
			</div>
		</div>
	)
}

export default FuzeActionComponent