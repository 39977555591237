import { useLocation } from "wouter"
import MoreIcon from '../../assets/icons/more-icon.svg'
import { useState } from "react"
import { deleteFuze, handleUpdateFuzeStatus } from "../../api/firebase"
import { getStatusColor } from "../../utils/helper"
import Notification from "../../common/Notification"

const FuzeComponent = ({
	subscriber,
	fuze,
	fuzeId,
	title,
	created_at,
	updated_at,
	connected_apps,
	status,
	reloadSubscriber,
}) => {
// eslint-disable-next-line
	const [location, navigate] = useLocation()
	const [fuzeOptions, setFuzeOptions] = useState(false)
	const [deletingFuze, setDeletingFuze] = useState(false)
	const [updatingFuzeStatus, setUpdatingFuzeStatus] = useState(false)

	const handleViewFuze = () => {
		navigate(`/fuze/${fuzeId}`)
	}

	const handleShowFuzeOptions = () => {
		setFuzeOptions(!fuzeOptions)
	}

	const handleDeleteFuze = async () => {
		setDeletingFuze(true)
		deleteFuze(subscriber, fuze)
		.then(() => {
			reloadSubscriber()
			.then(() => {
				setDeletingFuze(false)
			})
		})
	}

	const handleOnClickFuzeActionButton = async () => {
		setUpdatingFuzeStatus(true)

		switch(fuze.status) {
			case 'pending':
				return handleUpdateFuzeStatus(subscriber, fuze, 'active')
				.then(() => {
					reloadSubscriber()
					.then(() => {
						setUpdatingFuzeStatus(false)
						Notification('Your fuze is now active!', '', { icon: '🎉' })
					})
				})
			case 'paused':
				return handleUpdateFuzeStatus(subscriber, fuze, 'active')
				.then(() => {
					reloadSubscriber()
					.then(() => {
						setUpdatingFuzeStatus(false)
						Notification('Your fuze is now active!', '', { icon: '🎉' })
					})
				})
			case 'active':
				return handleUpdateFuzeStatus(subscriber, fuze, 'paused')
				.then(() => {
					reloadSubscriber()
					.then(() => {
						setUpdatingFuzeStatus(false)
						Notification('Your fuze is now paused!', '', { icon: '⌛️' })
					})
				})
			default:
				return
		}
	}

	return (
		<div key={fuzeId} className={`pl-[2%] pr-[2%] fuze-component flex flex-col md:flex-row lg:flex-row items-center w-full min-h-[80px] border-t-2 border-fuzerPurp animated-max-height ${deletingFuze ? 'pointer-events-none opacity-50' : ''}`}>
			<span className="mt-4 mb-4 md:m-0 lg:m-0 flex-[0.5] font-bold cursor-pointer hover:text-fuzerPurp select-none text-black dark:text-white" onClick={handleViewFuze}>{title}</span>
			<span className="mt-4 mb-4 md:m-0 lg:m-0 flex-[0.2] hidden md:flex lg:flex text-black dark:text-white">{updated_at}</span>
			<div className="mt-4 mb-4 md:m-0 lg:m-0 flex flex-[0.3] flex-wrap text-white">
				{[...new Set(connected_apps)].map((connected_app) => (
					<img key={connected_app} className="ml-[2.5px] mr-[2.5px] md:ml-[5px] md:mr-[5px] lg:ml-[5px] lg:mr-[5px]  w-[25px] h-[25px] md:w-[35px] md:h-[35px] lg:w-[35px] lg:h-[35px] select-none pointer-events-none" src={require(`../../assets/icons/apps/${connected_app}.svg`)} alt={`${connected_app} connected app icon`} />
					))}
			</div>
			<span className="relative flex-[0.2] flex items-center font-bold text-sm md:text-lg lg:text-lg text-black dark:text-white">
				{
					!fuzeOptions
					&&
					<span
						className={`rounded-lg w-[100px] text-center pt-[2px] pb-[2px] pl-[10px] pr-[10px] text-white text-base cursor-pointer hover:bg-opacity-80`}
						style={{
							backgroundColor: getStatusColor(status)
						}}
						onClick={handleOnClickFuzeActionButton}
					>
						{updatingFuzeStatus ? '...' : status.toUpperCase()}
					</span>
				}
				<div className="flex flex-col items-end justify-center">
					<span className={`mt-2 mb-2 grid place-items-center rounded-full w-[35px] h-[35px] ml-[35px] ${fuzeOptions ? 'bg-fuzerPurp bg-opacity-10' : ''} transition-colors hover:bg-fuzerPurp hover:bg-opacity-10 cursor-pointer select-none`} onClick={handleShowFuzeOptions}>
						<img className="h-[6px]" src={MoreIcon} alt="more icon" />
					</span>
					{
						fuzeOptions
						&&
						<div className="mb-[10px] rounded-2xl p-2 flex flex-col gap-2 justify-center w-[200px] border-opacity-40 backdrop-blur-[5px] z-[999]">
							<button className="fuzer-button text-[16px] bg-red-500 text-white animate-slideFromRight animation-delay" onClick={handleDeleteFuze}>Delete Fuze</button>
						</div>
					}
				</div>
			</span>
		</div>
	)
}

export default FuzeComponent