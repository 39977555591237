import { useEffect, useState } from 'react'
import FlowIcon from '../../assets/icons/flow-icon.svg'
import app_list from '../../utils/trigger_apps_list.json'
import { createBlankFuze } from '../../api/firebase'
import { useLocation } from 'wouter'
import Loader from '../../common/Loader'
import { getAppTriggerEventSelectOptions } from '../../utils/helper'
import HubspotFields from '../../actions/fields/HubspotFields'
import ConstantContactFields from '../../actions/fields/ConstantContactFields'
import StripeFields from '../../actions/fields/StripeFields'

const FuzeTrigger = ({
	subscriber,
	fuze,
	reloadSubscriber,
	setFuzeActions,
}) => {

	const [fuzeTrigger, setFuzeTrigger] = useState(null)
	const [fuzeTriggerEvent, setFuzeTriggerEvent] = useState(null)
	const [hubspotConnectPopup, setHubspotConnectPopup] = useState(null)
	const [stripeConnectPopup, setStripeConnectPopup] = useState(null)
	const [loading, setLoading] = useState(null)
	// eslint-disable-next-line
	const [location, navigate] = useLocation()

	const handleClosePopup = () => {
		switch(fuzeTrigger.app) {
			case 'hubspot':
				if(hubspotConnectPopup !== null) {
					hubspotConnectPopup.close()
				}
				break
			case 'stripe':
				if(stripeConnectPopup !== null) {
					stripeConnectPopup.close()
				}
				break
			default:
				return
		}
	}

	const handlePopupMessage = async (event) => {
		// Check the origin of the message to ensure it's from a trusted source
		if (event.origin !== window.location.origin) {
			return
		}

		const data = event.data

		switch(fuzeTrigger.app) {
			case 'hubspot':
				if(data.access_token) {
					setLoading('trigger-app-account-loading')
					reloadSubscriber()
					.then(() => {
						handleClosePopup()
						setLoading(null)
					})
				}
				break
			case 'stripe':
				if(data.webhookEndpoint) {
					setLoading('trigger-app-account-loading')
					reloadSubscriber()
					.then(() => {
						handleClosePopup()
						setLoading(null)
					})
				}
				break
			default:
				return
		}

	}

	const handleTriggerAccount = async (app) => {
		switch(app) {
			case 'hubspot':
				return setHubspotConnectPopup(window.open(`${window.location.origin}/connect/hubspot?accountId=${subscriber.accountId}`, "Connecting HubSpot account", "width=965,height=655"))
			case 'stripe':
				return setStripeConnectPopup(window.open(`${window.location.origin}/connect/stripe?accountId=${subscriber.accountId}`, "Connecting Stripe account", "width=965,height=655"))
			default:
				return null
		}
	}

	useEffect(() => {
		if(fuzeTrigger !== null) {
			// Add the event listener when the component mounts
			window.addEventListener('message', handlePopupMessage)
	
			// Remove the event listener when the component unmounts
			return () => {
				window.removeEventListener('message', handlePopupMessage)
			}
		}
		// eslint-disable-next-line
	}, [fuzeTrigger, hubspotConnectPopup, stripeConnectPopup])

	useEffect(() => {
		if(fuze) {
			if(fuze?.fuzeFlow?.trigger) {
				setFuzeTrigger(fuze.fuzeFlow.trigger)

				setFuzeTriggerEvent(fuze.fuzeFlow.event)
			}
			if(fuze?.fuzeFlow?.actions) {
				setFuzeActions(fuze.fuzeFlow.actions)
			}
		}
		// eslint-disable-next-line
	}, [fuze, subscriber])

	const handleChooseTrigger = (app) => {
		switch(app) {
			case 'hubspot':
				setLoading('fuze-loading')
				createBlankFuze(subscriber, app).then(async(fuzeId) => {
					reloadSubscriber().then(() => {
						navigate(fuzeId)
					})
				})
				break
			case 'stripe':
				setLoading('fuze-loading')
				createBlankFuze(subscriber, app).then(async(fuzeId) => {
					reloadSubscriber().then(() => {
						navigate(fuzeId)
					})
				})
				break
			default:
				return null
		}
	}


	const AdditionalFields = ({
		subscriber,
		fuze,
		reloadSubscriber,
	}) => {
		switch(fuze.fuzeFlow.trigger.app) {
			case 'hubspot':
				return <HubspotFields
					source="Trigger"
					subscriber={subscriber}
					fuze={fuze}
					reloadSubscriber={reloadSubscriber}
					/>
			case 'constant_contact':
				return <ConstantContactFields
					source="Trigger"
					subscriber={subscriber}
					fuze={fuze}
					reloadSubscriber={reloadSubscriber}
				/>
			case 'stripe':
				return <StripeFields
					source="Trigger"
					subscriber={subscriber}
					fuze={fuze}
					reloadSubscriber={reloadSubscriber}
				/>
			default:
				return
		}
	}

	return (
		<div className="overflow-hidden rounded-2xl max-w-[895px] w-[90%] min-h-[300px] ml-auto mr-auto mt-[25px] border-[1px] border-fuzerPurpDark shadow-xl animate-scaleUp">
			<div className="w-full h-full">
				<div className={`flex items-center pl-[15px] w-full h-[100px] ${fuzeTrigger === null ? 'bg-fuzerPurpDark' : 'bg-fuzerPurp'}`}>
					<img className='h-[55px] w-[55px] mr-[15px]' src={fuzeTrigger === null ? FlowIcon : require(`../../assets/icons/apps/${app_list.find((app) => app.name.toLowerCase() === fuzeTrigger.app.toLowerCase()).icon}`)} alt="flow icon" />
					<div className="w-[70%] flex flex-col">
						<span className='w-fit text-lg md:text-3xl lg:text-3xl text-white font-bold'>{fuzeTrigger === null ? "Trigger" : `${app_list.find((app) => app.name.toLowerCase() === fuzeTrigger.app.toLowerCase()).name} --> Trigger`}</span>
						<span className='mt-[2px] text-sm md:text-base lg:text-base text-white font-medium'>A trigger is an event that starts your Fuze</span>
					</div>
				</div>
				{
					fuzeTrigger === null
					?
					<div className='h-full md:h-[200px] lg:h-[200px] pt-6 pb-6 md:p-0 lg:p-0 w-full flex flex-col justify-center items-center'>
						{
							loading !== 'fuze-loading'
							?
							<>
								<span className='mb-[25px] text-xl font-semibold text-fuzerPurpDark dark:text-white'>Choose an app for the trigger</span>
								<div className='flex flex-col md:flex-row lg:flex-row flex-wrap'>
									{
										app_list.map((app) => (
											<div key={app.name} className='mt-2 rounded-xl pt-2 pb-2 pl-4 pr-4 ml-2 mr-2 flex items-center border-2 border-fuzerPurpDark dark:border-fuzerPurp cursor-pointer transition-opacity hover:opacity-70' onClick={() => handleChooseTrigger(app.name.toLowerCase())}>
												<img className='w-[50px] mr-4 pointer-events-none' src={require(`../../assets/icons/apps/${app.icon}`)} alt="app icon" />
												<span className='text-fuzerPurpDark dark:text-white text-2xl font-semibold select-none'>{app.name}</span>
											</div>
										))
									}
								</div>
							</>
							:
							<Loader size={80} />
						}
					</div>
					:
					<div className='pt-[15px] pb-[25px] flex flex-col items-center w-full'>
						<div className='pl-[15px] pr-[15px] flex items-center rounded-lg w-[95%] h-[80px] border-[1px] border-gray-300'>
							<img className='w-[30px] mr-4 pointer-events-none' src={require(`../../assets/icons/apps/${app_list.find((app) => app.name.toLowerCase() === fuzeTrigger.app.toLowerCase()).icon}`)} alt="flow icon" />
							<span className='text-black dark:text-white text-2xl font-semibold select-none'>{app_list.find((app) => app.name.toLowerCase() === fuzeTrigger.app.toLowerCase()).name}</span>
							{/* <button className='change-trigger-button ml-auto'>Change</button> */}
						</div>
						<div className='mt-[15px] pl-[15px] pr-[15px] flex flex-col w-[95%] h-fit'>
							<span className='text-black dark:text-white text-md font-medium select-none'><b>* {app_list.find((app) => app.name.toLowerCase() === fuzeTrigger.app.toLowerCase()).name} account</b> (required)</span>
							<div className='flex'>
								{
									loading !== 'trigger-app-account-loading' ?
									subscriber?.connected_accounts[fuzeTrigger.app]
									?
									<div className='mt-2 pl-[15px] pr-[15px] p-4 flex flex-col md:flex-row lg:flex-row md:items-center lg:items-center w-full h-full md:h-[80px] lg:h-[80px] rounded-lg border-[1px] border-gray-300'>
										<img className='w-[20px] mr-4 pointer-events-none' src={require(`../../assets/icons/apps/${app_list.find((app) => app.name.toLowerCase() === fuzeTrigger.app.toLowerCase()).icon}`)} alt="flow icon" />
										<span className='text-black dark:text-white text-lg font-semibold select-none'>{`${subscriber.connected_accounts[fuzeTrigger.app]?.user?.email} ${subscriber.connected_accounts[fuzeTrigger.app]?.user?.portalId ? `(${subscriber.connected_accounts[fuzeTrigger.app]?.user?.portalId})` : ''}` || 'Connect a new account'}</span>
										<button className='change-trigger-account-button h-[45px] ml-auto' onClick={() => handleTriggerAccount(fuzeTrigger.app)}>{subscriber.connected_accounts[fuzeTrigger.app] ? 'Update' : 'Connect'}</button>
									</div>
									:
									<div className='mt-2 pl-[15px] pr-[15px] p-4 flex flex-col md:flex-row lg:flex-row md:items-center lg:items-center w-full h-full md:h-[80px] lg:h-[80px] border-[1px] border-gray-300'>
										<img className='w-[20px] mr-4 pointer-events-none' src={require(`../../assets/icons/apps/${app_list.find((app) => app.name.toLowerCase() === fuzeTrigger.app.toLowerCase()).icon}`)} alt="flow icon" />
										<span className='text-black dark:text-white text-2xl font-semibold select-none'>Connect a new account</span>
										<button className='change-trigger-account-button h-[45px] ml-auto' onClick={() => handleTriggerAccount(fuzeTrigger.app)}>Connect</button>
									</div>
									:
									<div className='mt-2 skeleton-loading w-full h-[80px] rounded-lg' />
								}
								</div>
								<div className='mt-[15px] pl-[15px] pr-[15px] flex flex-col w-[95%] h-[80px]'>
									<span className='text-black dark:text-white text-xl font-medium select-none'><b>* Event</b> (required)</span>
									<select className='mt-2 text-black dark:text-white text-2xl font-medium select-none bg-transparent appearance-none border-2 p-2 cursor-pointer' value={fuzeTriggerEvent} onChange={(e) => setFuzeTriggerEvent(e.target.value)}>
										{getAppTriggerEventSelectOptions(fuzeTrigger.app)}
									</select>
								</div>
								<AdditionalFields
									subscriber={subscriber}
									fuze={fuze}
									reloadSubscriber={reloadSubscriber}
								/>
						</div>
					</div>
				}
				
			</div>
		</div>
	)
}

export default FuzeTrigger