import { useEffect, useState } from "react"
import { useLocation } from "wouter"
import FuzeActions from "../../components/FuzeActions"
import FuzeTrigger from "../../components/FuzeTrigger"
import ConfirmDiscardFuzeEditsModal from "../../modals/ConfirmDiscardFuzeEditsModal"
import FuzePageHeader from "../../components/FuzePageHeader"

const FuzePage = ({
	subscriber,
	fuzeId,
	reloadSubscriber,
}) => {

	// eslint-disable-next-line
	const [location, navigate] = useLocation()

	const [fuze, setFuze] = useState(null)
	const [showConfirmDiscardFuzeEditsModal, setShowConfirmDiscardFuzeEditsModal] = useState(false)

	const [fuzeActions, setFuzeActions] = useState([])

	useEffect(() => {
		if(subscriber !== null && fuzeId !== 'new') {
			setFuze(subscriber.fuzes.find(fuze => fuze.fuzeId === fuzeId))
		} else {
			setFuze({
				status: 'pending',
				connected_app: [],
				title: 'My New Fuze',
				fuzeId: 'untitled'+new Date().getMilliseconds(),
				fuzeFlow: {
					actions: [],
					trigger: null
				}
			})
		}
		// eslint-disable-next-line
	}, [subscriber])

	return (
		subscriber && fuze &&
		<div className="w-screen max-w-full">
			{/* Header Section */}
			<FuzePageHeader
				subscriber={subscriber}
				reloadSubscriber={reloadSubscriber}
				fuze={fuze}
				showConfirmDiscardFuzeEditsModal={showConfirmDiscardFuzeEditsModal}
				setShowConfirmDiscardFuzeEditsModal={setShowConfirmDiscardFuzeEditsModal}
			/> 
			{/* Fuze Trigger and Actions */}
			<FuzeTrigger subscriber={subscriber} fuze={fuze} reloadSubscriber={reloadSubscriber} setFuzeActions={setFuzeActions}/>
			<FuzeActions subscriber={subscriber} fuze={fuze} reloadSubscriber={reloadSubscriber} fuzeActions={fuzeActions}/>
			<ConfirmDiscardFuzeEditsModal show={showConfirmDiscardFuzeEditsModal} onCancel={() => setShowConfirmDiscardFuzeEditsModal(false)} onDiscard={() => navigate('/')}/>
		</div>
	)
}

export default FuzePage