import axios from "axios"
import { useState } from "react"
import WindowSplashLoader from "../common/WindowSplashLoader"
import FuzerIcon from '../assets/icons/fuzer-icon.svg'

const StripeConnectSplash = () => {

  const [isInsideWindow] = useState(!!window.opener)
  const [accountId] = useState(new URLSearchParams(window.location.search).get('accountId'))

  const [loading, setLoading] = useState(false)
  
  const handleConnectStripeAccount = async (apiKey) => {
    console.log(accountId);
    const getStripeAuthAccess = {
      url: `http://127.0.0.1:5001/fuzer-app/us-central1/handleStripeAccountOAuth/access/${accountId}`,
      method: 'POST',
      data: {
        apiKey,
      }
    }

    if(accountId && apiKey) {
      setLoading(true)
      const stripeAccess = (await axios(getStripeAuthAccess)).data

      if(stripeAccess.webhookEndpoint) {
        const dataToSend = {
          webhookEndpoint: stripeAccess.webhookEndpoint,
        }
        
        window.opener.postMessage(dataToSend, window.location.origin)
      }
    }
  }

  const ConnectAccountWrapper = () => {
    const [apiKey, setApiKey] = useState('')

    return (
      <div className="h-full w-full flex flex-col items-center justify-center dark:bg-darkThemeBg">
        <img className='mb-[20px] md:mt-0 lg:mt-0 md:mr-[20px] lg:mr-[20px] h-[80px]' src={FuzerIcon} alt="fuzer logo" />
        <p className="mb-4 text-black dark:text-white text-2xl ">
          Create a new connection to Stripe
        </p>
        <div className="flex flex-col border-[1px] border-fuzerPurp p-[25px]">
          <p className="text-lg text-black dark:text-white">To connect Fuzer to your Stripe account:</p>
          <ul className="ml-8 list-disc text-black dark:text-white">
            <li>Create a Stripe API key</li>
            <li>Make sure to create either a Secret or Restricted key</li>
            <li>Enter the API key below</li>
          </ul>
        </div>
        <span className="w-[80%] md:w-[480px] lg:w-[480px] mt-6 mb-2 flex flex-col">
          <label className="text-lg text-fuzerPurp font-bold">Enter API key</label>
          <input
            className="rounded w-full p-2 pl-4 pr-4 text-xl bg-transparent border-2 border-fuzerPurp text-black dark:text-white"
            type="text"
            placeholder="value"
            value={apiKey}
            onChange={(e) => setApiKey(e.target.value)}
          />
        </span>
        <button
          className="mt-4 fuzer-button text-white bg-fuzerPurp"
          onClick={() => handleConnectStripeAccount(apiKey)}
        >
          Connect
        </button>
      </div>
    )
  }

  return (
    <div className="flex items-center justify-center app h-screen w-full bg-white">
      {
        isInsideWindow
        ?
        loading ? <WindowSplashLoader app={"stripe"} /> : <ConnectAccountWrapper />
        :
        <div className="h-screen w-full grid place-items-center">
          <span>Not in a window</span>
        </div>
      }
    </div>
  )
}

export default StripeConnectSplash