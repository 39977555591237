import { useState } from "react"
import FuzeList from "../../components/FuzeList"
import { useLocation } from "wouter"

const DashboardPage = ({
	subscriber,
	reloadSubscriber,
}) => {

	const [isCreatingFuze, setIsCreatingFuze] = useState(false)

	// eslint-disable-next-line
	const [location, navigate] = useLocation()

	const handleCreateFuze = () => {
		navigate(`/fuze/new`)
	}

	return (
		subscriber &&
		<div className="mt-[50px] flex flex-col items-center justify-center h-full w-full">
			<div className="flex flex-col items-center mb-[50px]">
				{subscriber.returning
					?
					<>
						<span className="text-3xl md:text-4xl lg:text-4xl text-black dark:text-white">Welcome back, {subscriber?.name.split(/\s/)[0]}!</span>
						<span className="mt-2 text-xl text-gray-500">Let's get back to work.</span>
					</>
					:
					<>
						<span className="text-4xl text-black dark:text-white">Hi, {subscriber?.name.split(/\s/)[0]}!</span>
						<span className="mt-2 text-xl text-gray-500">Let's automate stuff.</span>
					</>
				}
			</div>
			<div className="mb-[25px]">
				<button
					className="create-fuze-button"
					onClick={handleCreateFuze}
					onMouseEnter={() => setIsCreatingFuze(true)}
					onMouseLeave={() => setIsCreatingFuze(false)}
				>
					Create a Fuze
				</button>
			</div>
			<FuzeList subscriber={subscriber} isCreatingFuze={isCreatingFuze} reloadSubscriber={reloadSubscriber} />
		</div>
	)
}

export default DashboardPage