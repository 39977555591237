import FuzerIcon from '../../assets/icons/fuzer-icon.svg'
import FuzerLoader from '../../assets/icons/fuzer-loader-icon.svg'

const WindowSplashLoader = ({ app }) => (
	<div className='flex flex-col w-[80%] md:w-[520px] lg:w-[500px]'>
	<div className='flex flex-col md:flex-row lg:flex-row items-center justify-between'>
		<img className='mb-[20px] md:mt-0 lg:mt-0 md:mr-[20px] lg:mr-[20px] h-[80px]' src={FuzerIcon} alt="fuzer logo" />
		<div className='w-[180px] md:w-[300px] lg:w-[300px] h-[280px] md:h-auto lg:h-auto flex flex-col md:flex-row lg:flex-row justify-between items-center'>
			<span className='rounded-full h-[5px] w-[5px] bg-fuzerPurp hidden md:block lg:block'/>
			<span className='rounded-full h-[5px] w-[5px] bg-fuzerPurp hidden md:block lg:block'/>
			<span className='rounded-full h-[5px] w-[5px] bg-fuzerPurp'/>
			<span className='rounded-full h-[5px] w-[5px] bg-fuzerPurp'/>
			<span className='rounded-full h-[5px] w-[5px] bg-fuzerPurp'/>
			<img className='ml-2 mr-2 rounded-full h-[80px] animate-spin' src={FuzerLoader} alt="app loader" />
			<span className='rounded-full h-[5px] w-[5px] bg-fuzerPurp hidden md:block lg:block'/>
			<span className='rounded-full h-[5px] w-[5px] bg-fuzerPurp hidden md:block lg:block'/>
			<span className='rounded-full h-[5px] w-[5px] bg-fuzerPurp hidden md:block lg:block'/>
			<span className='rounded-full h-[5px] w-[5px] bg-fuzerPurp'/>
			<span className='rounded-full h-[5px] w-[5px] bg-fuzerPurp'/>
		</div>
			<img className='mt-[20px] md:mt-0 lg:mt-0 md:ml-[20px] lg:ml-[20px] h-[80px]' src={require(`../../assets/icons/apps/${app}.svg`)} alt="app logo" />
	</div>
</div>
) 

export default WindowSplashLoader