export const getStatusColor = (status) => {
	switch(status) {
		case 'pending':
			return '#ffc107'
		case 'paused':
			return '#ffc107'
		case 'active':
			return '#28a745'
		case 'errored':
			return '#dc3545'
		default:
			return
	}
}

export const getAppTriggerEventSelectOptions = (app) => {
	switch(app) {
		case 'hubspot':
			return (
				<>
					<option value="new_form_submission">New Form Submission</option>
				</>
			)
		case 'stripe':
			return (
				<>
					<option value="new_payment">New Payment</option>
				</>
			)
		default:
			return
	}
}

export const getActionEventSelectOptions = (app) => {
	switch(app) {
		case 'hubspot':
			return (
				<>
					<option value="new_field">New Field</option>
				</>
			)
		case 'constant_contact':
			return (
				<>
					<option value="create_contact">Create Contact</option>
				</>
			)
		default:
			return
	}
}

export const getAppActionEventDescription = (app, event) => {
	switch(app) {
		case 'hubspot':
			switch (event) {
				case 'new_field':
					return 'This event will add new payment as TRUE to the provided field'
				default:
					break;
			}
			break;
		case 'constant_contact':
			switch (event) {
				case 'create_contact':
					return 'This event will create a new contact'
				default:
					break;
			}
			break;
		default:
			return
	}
}
