import { useState } from 'react'
import { createBlankFuzeAction } from '../../api/firebase'
import AddIcon from '../../assets/icons/add-icon.svg'
import LoaderIcon from '../../assets/icons/loader-icon.svg'
import FuzeActionComponent from '../FuzeActionComponent'

const FuzeActions = ({
	subscriber,
	fuze,
	fuzeActions,
	reloadSubscriber,
}) => {

	
	const handleCreateNewFuzeAction = async (actionIndex) => {
		return await createBlankFuzeAction(subscriber, fuze, actionIndex)
	}
	
	const AddNewFuzeAction = ({ index }) => {
		const [creatingAction, setCreatingAction] = useState(false)
		
		const handleClickCreateNewFuzeAction = () => {
			if(!creatingAction) {
				let actionIndex = index
				if(actionIndex<0) {
					actionIndex = 0
				}
	
				setCreatingAction(true)
				handleCreateNewFuzeAction(actionIndex)
				.then(() => {
					reloadSubscriber()
					.then(() => {
						setCreatingAction(false)
					})
				})
			}
		}

		return (
			<span
				className="rounded-full grid place-items-center w-[35px] h-[35px] text-black text-3xl bg-fuzerPurp border-2 border-fuzerPurpDark leading-tight select-none cursor-pointer hover:scale-110 transition-transform"
				onClick={handleClickCreateNewFuzeAction}
			>
				{
					!creatingAction
					?
					<img
						className='w-[22px] h-[22px] invisible animate-new-action-button'
						src={AddIcon}
						alt="add icon"
					/>
					:
					<img
						className='w-[18px] h-[18px] rounded-full animate-spin'
						src={LoaderIcon}
						alt="loader icon"
					/>
				}
			</span>
		)
	}

	return (
		<div className="w-full flex flex-col items-center">
			{
				fuzeActions.length>=0 && fuze.fuzeFlow?.trigger?.app
				&&
				<>
					<span className="w-[1px] h-[35px] bg-fuzerPurp"/>
					<AddNewFuzeAction index={0} />
				</>
			}
			{
				fuzeActions.map((action) => (
					<div className='w-full flex flex-col items-center' key={action.id}>
						<span className="w-[1px] h-[35px] bg-fuzerPurp"/>
						<FuzeActionComponent subscriber={subscriber} reloadSubscriber={reloadSubscriber} fuze={fuze} action={action}/>
						<span className="w-[1px] h-[35px] bg-fuzerPurp"/>
						{
							action.id === fuzeActions[fuzeActions.length-1].id
							&&
							<div className="mb-[150px]">
								<AddNewFuzeAction index={(fuzeActions.findIndex((a) => a.id === action.id)+1)}/>
							</div>
						}
					</div>
				))
			}
		</div>
	)
}

export default FuzeActions