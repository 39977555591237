import moment from "moment"
import { useEffect, useState } from "react"
import FuzeComponent from "../FuzeComponent"
import FuzeListGraphic from '../../assets/graphic/fuze-list-graphic.svg'
import SadEmoIcon from '../../assets/icons/sad-emo.svg'
import CoolEmoIcon from '../../assets/icons/cool-emo.svg'
import CelebrateEmoIcon from '../../assets/icons/celebrate-emo.svg'


const FuzeList = ({
	subscriber,
	isCreatingFuze,
	reloadSubscriber,
}) => {

	const [fuzes, setFuzes] = useState([])

	useEffect(() => {
		if(subscriber !== null) {
			setFuzes(subscriber.fuzes)
		} else {
			setFuzes([])
		}
	}, [subscriber])

	return (
		<div className="w-[80%] md:w-[90%] mb-12 md:mb-0 lg:mb-0">
			{fuzes.length>0
				&&
				<div className="pl-[2%] pr-[2%] flex pt-[15px] pb-[15px] text-[#000000] dark:text-white font-bold">
					<span className="flex-[0.5]">Name</span>
					<span className="flex-[0.2] hidden md:flex lg:flex">Last updated</span>
					<span className="flex-[0.3]" />
					<span className="flex-[0.2]">Status</span>
				</div>
			}
			{fuzes.map((fuze) => (
				<FuzeComponent key={fuze.fuzeId} subscriber={subscriber} fuze={fuze} fuzeId={fuze.fuzeId} title={fuze.title} created_at={moment.unix(fuze.created_at).fromNow()} updated_at={moment.unix(fuze.updated_at).fromNow()} connected_apps={fuze.connected_apps} status={fuze.status} reloadSubscriber={reloadSubscriber} />
			))}
			{
				fuzes.length===0
				&&
				<div className="mt-[50px] w-full flex flex-col justify-center items-center">
					<div className="text-fuzerPurp text-[24px] font-normal flex flex-col w-fit">
						 {
							subscriber.returning &&
							<>
								{
									!isCreatingFuze
									?
									<img className="h-[50px]" src={SadEmoIcon} alt="" />
									:
									<img className="h-[50px] transition-all pointer-events-auto" style={{ animation: 'giggle 0.5s ease-in-out' }} src={CoolEmoIcon} alt="" />
								}
								<span className="mt-[15px] h-[200px]">Sadly, there are no fuzes available.</span>
							</>
							}
					</div>
					<span className="relative mt-[100px] text-fuzerPurp text-[25px] font-medium block">Let's create your first ever <b>Fuze</b> and automate some stuff. <img className="mt-[15px] ml-auto h-[80px] animate-scaleUp delay-1000" src={CelebrateEmoIcon} alt="celebrate emoji " />
						<img className="absolute top-[25px] mt-[50px] h-[300px] md:h-[400px] lg:h-[400px] pointer-events-none" src={FuzeListGraphic} alt="fuze graphic" />
					</span>
				</div>
			}
		</div>
	)
}

export default FuzeList