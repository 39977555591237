import { useEffect, useState } from "react"
import { updateCustomFields, updateFields } from "../../api/firebase"
import { getHubspotForms } from "../../api/hubspot"

const HubspotFields = ({
	source,
	subscriber,
	fuze,
	action,
	reloadSubscriber,
}) => {

	const [forms, setForms] = useState([])
	const [selectedForm, setSelectedForm] = useState('default')

	const [loading, setLoading] = useState('false')

	const [customFields, setCustomFields] = useState([])

	const loadHubspotForms = (accountId) => {
		setLoading('lists-loading')
		getHubspotForms(accountId)
			.then((forms) => {
				setForms(forms)
				setLoading(false)
			})
	}

	useEffect(() => {
		if(source === 'Trigger') {
			if(fuze?.fuzeFlow?.trigger?.app === 'hubspot' && fuze?.fuzeFlow?.trigger?.event === 'new_form_submission') {
				loadHubspotForms(subscriber.accountId)
			}
		}
		// eslint-disable-next-line
	}, [])

	useEffect(() => {
		if(fuze?.fuzeFlow?.trigger?.fields?.form) {
			setSelectedForm(fuze.fuzeFlow.trigger.fields.form)
		}
		// eslint-disable-next-line
	}, [action])

	useEffect(() => {
		if(source === 'Action') {
			if(action?.customFields) {
				setCustomFields(action.customFields)
			}
		}
		// eslint-disable-next-line
	}, [action])

	// SELECT FORM FIELD
	
	const handleUpdateForms = (form) => {
		setLoading('lists-loading')
		
		if(form !== selectedForm) {
			setSelectedForm(null)
			updateFields({
					source,
					subscriber,
					fuze,
					fieldLabel: 'form',
					fieldValue: form,
			})
				.then(() => {
					reloadSubscriber()
				})
		}
	}

	// CUSTOM FIELD

	const handleChangeCustomField = (value, field, index) => {
    const regex = /^[a-z_]*$/
    if(field === 'label') {
			if (value === '' || regex.test(value)) {
					setCustomFields(customFields.map((customField, i) => {
							if(i !== index) return customField
							return {...customField, [field]: value}
					}))
			}
		}
    if(field === 'value') {
			setCustomFields(customFields.map((customField, i) => {
					if(i !== index) return customField
					return {...customField, [field]: value}
			}))
		}
	}

	const handleUpdateCustomField = () => {
		setLoading('custom-fields')

		const fields = customFields.filter((field) => field.label)
		
		updateCustomFields({
			subscriber,
			fuze,
			action,
			customFields: fields,
		})
		.then(() => {
			reloadSubscriber()
			setLoading('false')
		})
	}
	
	const handleDeleteCustomField = (fieldLabel) => {
		setLoading('custom-fields')

		const fields = customFields.filter((field) => field.label !== fieldLabel)
		
		updateCustomFields({
			subscriber,
			fuze,
			action,
			customFields: fields,
		})
		.then(() => {
			reloadSubscriber()
			setLoading('false')
		})
	}

	const addCustomField = () => {
    setCustomFields([
        ...customFields, 
        { label: '', value: '' }
    ])
	}

	return (
			<>
				<div className='mt-[15px] pl-[15px] pr-[15px] flex flex-col w-[95%] h-fit'>
						{
							source === 'Trigger'
							&& 
								<>
									<span className='text-black dark:text-white text-xl font-medium select-none'><b>* FORM </b> (required)</span>
									<select
										className={`mt-2 text-black dark:text-white bg-transparent appearance-none border-2 p-2 outline-none text-2xl font-medium cursor-pointer select-none disabled:opacity-50 ${loading === 'lists-loading' && 'skeleton-loading'}`} 
										value={selectedForm}
										onChange={(e) => handleUpdateForms(e.target.value)}
										disabled={forms.length===0}
									>
										{selectedForm === 'default' && <option value="default" defaultValue>Select value</option>}

										{selectedForm !== null
										&&
											forms.map((form) => (
												<option key={form.id} value={form.id}>{form.name}</option>
											))
										}
									</select>
							</>
						}
						{
							source === 'Action'
							&& 
								<>
									{
										action.event === 'new_field'
										&&
										<>
											<button 
												className="mb-4 w-fit fuzer-button bg-fuzerPurp text-white text-xl" 
												onClick={addCustomField}
											>
													+ ADD NEW FIELD
											</button>
											{
												customFields.map((field, index) => (
													<div className="w-fit flex justify-between items-center" key={index}>
															<input
																	className="rounded fuze-action-custom-field-input"
																	type="text"
																	value={field.label}
																	onChange={(e) => handleChangeCustomField(e.target.value, 'label', index)}
																	placeholder="field_name"
															/>
															<span className="text-fuzerPurp text-2xl font-bold pl-4 pr-4">{`-->`}</span>
															<input
																	className="rounded fuze-action-custom-field-input"
																	type="text"
																	value={field.value}
																	onChange={(e) => handleChangeCustomField(e.target.value, 'value', index)}
																	placeholder="field_value"
															/>
														<button
															className="ml-4 h-[30px] w-[30px] grid place-items-center rounded-full cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:bg-opacity-100"
															onClick={() => handleDeleteCustomField(field.label)}
															disabled={loading === 'custom-fields'}
														>
															<img src={require('../../assets/icons/no-icon.svg').default} alt="delete icon" />
														</button>
													</div>
											))
										}
										{
											customFields.length>0
											&&
											<button
												className="mt-4 w-fit fuzer-button bg-fuzerPurp text-white text-xl disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:bg-opacity-100"
												onClick={() => handleUpdateCustomField()}
												disabled={loading === 'custom-fields' || action?.customFields === customFields}
											>
												UPDATE 
											</button>
										}
									</>
									}
							</>
						}
				</div>
			</>
	)
}

export default HubspotFields