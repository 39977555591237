import React from 'react'
import Modal from '../Modal'

const ConfirmDiscardFuzeEditsModal = ({
	show,
	onDiscard,
	onCancel,
}) => {

	return (
		show &&
		<Modal title="Your changes are unsaved and all the edits will be discarded 😥" customClass={`w-[90%] md:w-[450px] lg:w-[500px]`}>
			<div className='h-[80px] w-full flex items-center justify-center'>
				<button className='fuzer-button discard-button text-[24px] mr-[5%]' onClick={onDiscard}>Discard</button>
				<button className='fuzer-button bg-gray-200 text-[24px]' onClick={onCancel}>Cancel</button>
			</div>
		</Modal>
	)
}

export default ConfirmDiscardFuzeEditsModal